<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<v-row dense>
						<!-- PORTID -->
						<v-col cols="4">
							<pui-select
								:id="`portname-${modelName}`"
								:attach="`portname-${modelName}`"
								:label="this.$t('form.shipserviceap.portname')"
								toplabel
								clearable
								required
								:disabled="!isCreatingElement"
								v-model="model"
								modelName="port"
								:itemsToSelect="[{ id: model.portid }]"
								:modelFormMapping="{ id: 'portid' }"
								itemValue="id"
								itemText="portname"
								reactive
								:fixedFilter="filterByUserPortsAndAuthority"
							></pui-select>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- SHIPID -->
						<v-col cols="4">
							<pui-select
								id="shipname-shipserviceap"
								attach="shipname-shipserviceap"
								:label="$t('form.shipserviceap.shipname')"
								toplabel
								clearable
								v-model="model"
								modelName="shipshiptypenswpa"
								:disabled="!isCreatingElement"
								:modelFormMapping="{ id: 'shipid' }"
								:itemsToSelect="[{ id: model.shipid }]"
								:itemValue="['id']"
								:itemText="(item) => `${item.padescription} - ${item.name} - ${item.imo} - ${item.mmsi ? item.mmsi : ''}`"
								:order="{ name: 'asc' }"
								:fixedFilter="filterShipVersion"
								required
							></pui-select>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- NOANCHORAGEBERTH -->
						<v-col cols="3" class="align-self-center">
							<pui-checkbox
								:id="`noanchorageberth-shipserviceap`"
								:label="$t('form.shipserviceap.noanchorageberth')"
								v-model="model.noanchorageberth"
							></pui-checkbox>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- NOEVENTS -->
						<v-col cols="3" class="align-self-center">
							<pui-checkbox
								:id="`noevents-shipserviceap`"
								:label="$t('form.shipserviceap.noevents')"
								v-model="model.noevents"
							></pui-checkbox>
						</v-col>
					</v-row>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns :formDisabled="formDisabled" :saveDisabled="saving" :save="save" :back="back"></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'shipserviceap-form',
	mixins: [PuiFormMethodsMixin],
	components: {},
	data() {
		return {
			modelName: 'shipserviceap',
			ownlistships: false
		};
	},
	methods: {
		afterGetData() {
			//Get portauthority data
			const opts = {
				model: 'portauthority',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'id',
							op: 'eq',
							data: this.$store.getters.getPortAuthorityId
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data && response.data.data[0]) {
					//Fill data
					this.ownlistships = response.data.data[0].ownlistships;
				} else {
					this.ownlistships = false;
				}
			});
			this.model.portauthorityid = this.$store.getters.getPortAuthorityId;
			if (this.isCreatingElement) {
				this.model.noanchorageberth = true;
				this.model.noevents = false;
			}
		}
	},
	computed: {
		filterByUserPortsAndAuthority() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'id', op: 'in', data: this.$store.getters.getPortsIds },
					{ field: 'portauthority', op: 'eq', data: this.$store.getters.getPortAuthorityId }
				]
			};
		},
		filterShipVersion() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'next_version_id', op: 'nu', data: null },
					{ field: 'type_port_authority_id', op: 'eq', data: this.$store.getters.getPortAuthorityId },
					this.ownlistships
						? { field: 'port_authority_id', op: 'eq', data: this.$store.getters.getPortAuthorityId }
						: { field: 'port_authority_id', op: 'nu', data: null }
				]
			};
		}
	},
	created() {}
};
</script>
